import {AfterViewInit, Component, computed} from "@angular/core";
import { AuthStoreService } from "../../../features/auth/infrastructure/services/auth-store/auth-store.service";
import { AuthApiService } from "../../../features/auth/infrastructure/services/auth-api/auth-api.service";
import {Router, RouterLink, RouterLinkActive} from "@angular/router";
import {Theme, ThemeStoreService} from "../../../shared/infrastructure/theme/theme-store.service";
import {capitalCase} from 'change-case';
import {NgbDropdown, NgbDropdownItem, NgbDropdownMenu, NgbDropdownToggle} from "@ng-bootstrap/ng-bootstrap";
import {TranslocoPipe} from "@jsverse/transloco";
import {NgIf} from "@angular/common";
import {PlatformsApiDTO} from "../../../features/palataforms/palataforms.service";
import {PlatformStoreService} from "../../../shared/infrastructure/services/platform-store.service";
import {environment} from "../../../../environments/environment";
import {
  SelectorPlatformComponent
} from '../../../shared/infrastructure/components/selector-platform/selector-platform.component';
import {
  PermissionsStoreService
} from '../../../features/permissions/infrastructure/services/permissions-store/permissions-store.service';

export interface NavbarButton {
  name: string,
  translationRoute: string,
  linkRoute: string
}
@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"],
  standalone: true,
    imports: [
        RouterLinkActive,
        RouterLink,
        NgbDropdown,
        NgbDropdownToggle,
        NgbDropdownMenu,
        NgbDropdownItem,
        TranslocoPipe,
        NgIf,
        SelectorPlatformComponent
    ]
})
export class NavbarComponent implements AfterViewInit{
	firstname = computed(() => this.authStore.user()?.firstname);
	navbarCollapsed = true;

  public rolePermissions: any;
  platform!: PlatformsApiDTO;

	constructor(
		public authStore: AuthStoreService,
		private permissionsStore: PermissionsStoreService,
		private authApi: AuthApiService,
		private router: Router,
    private themeStoreService: ThemeStoreService,
    private platformStoreService: PlatformStoreService,
	) {
    this.rolePermissions = this.permissionsStore.getCurrentRolePermissions()
    this.platformStoreService.platform.subscribe((platform) => {
      if (platform) this.platform = platform
    })


    /*try {
      this.platform = parseJson(this.platformStoreService.getPlatformFromStorage()!)
    }catch (e) {
      this.platformStoreService.platform.subscribe((platform) => {
        if (platform) this.platform = platform
      })
    }*/
  }

  ngAfterViewInit() {
    this.rolePermissions = this.permissionsStore.getCurrentRolePermissions()
  }

  logout() {
		this.authApi.logout(this.authStore.refreshToken()!).subscribe(() => {
			this.authStore.logout();
      this.platformStoreService.clearPlatform()
			this.router.navigate(["/auth"]);
		});

	}

  changeTheme(theme: string) {
    switch (theme) {
      case 'light':
        this.themeStoreService.changeTheme(Theme.Light)
        break;
      case 'dark':
        this.themeStoreService.changeTheme(Theme.Dark)
        break;
      case 'auto':
        this.themeStoreService.changeTheme(Theme.Auto)
        break;
      default:
        throw new Error(`Invalid theme: '${theme}'`);
    }
  }

  getThemeIcon() {
    const theme = this.themeStoreService.theme();
    switch (theme) {
      case Theme.Auto:
        return 'bi-circle-half';
      case Theme.Dark:
        return 'bi-moon-stars-fill';
      case Theme.Light:
        return 'bi-brightness-high-fill';
    }
  }

  onImageError(event: Event) {
    const target = event.target as HTMLImageElement;
    target.src = 'assets/images/logo-white.png'; // Imagen de respaldo
  }

  getThemeName() {
    return capitalCase(this.themeStoreService.theme().toString());
  }

  protected readonly environment = environment;
}
