<div class="mx-auto d-flex" style="max-width: 500px">
<!--  <label class="form-label pt-1">
    Plataforma:
  </label>-->

  <select class="form-select shadow-none" [(ngModel)]="selectedPlatform" (ngModelChange)="switchPlatform()" style="width: 200px" role="button">
    <option *ngFor="let platform of platforms" [value]="platform.id">
      {{platform.name}}
    </option>
  </select>
</div>
