import { Injectable } from "@angular/core";
import {HttpClient, HttpResponse} from "@angular/common/http";
import { map, Observable } from "rxjs";
import {Criteria} from "@zertifier/criteria";
import {environment} from '../../../../../../environments/environment';
import {HttpUtils} from '../../../../../shared/infrastructure/http/HttpUtils';

export interface PermissionResponseDTO {
	resource: string;
	action: string;
	role: string;
	allow: boolean;
}

@Injectable({
	providedIn: 'root',
})
export class PermissionsApiService {
	constructor(private httpClient: HttpClient) {}

	get(criteria: Criteria): Observable<Array<PermissionResponseDTO>> {
		return this.httpClient
			.get<HttpResponse<Array<PermissionResponseDTO>>>(
				`${environment.api_url}/permissions?${HttpUtils.convertCriteriaToQueryParams(criteria)}`,
			)
			.pipe(map((response: any) => response.data));
	}

	save(permissions: Array<PermissionResponseDTO>): Observable<void> {
		return this.httpClient
			.put<HttpResponse<void>>(`${environment.api_url}/permissions`, { permissions })
			.pipe(map((response: any) => response.data));
	}
}
