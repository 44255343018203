import {Component, OnDestroy} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {Subscription} from "rxjs";
import {NgForOf} from "@angular/common";
import {PalataformsApiService, PlatformsApiInterface} from "../../../../features/palataforms/palataforms.service";
import {PlatformStoreService} from "../../services/platform-store.service";
import {parseJson} from "@angular/cli/src/utilities/json-file";

@Component({
  selector: 'app-selector-platform',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    FormsModule,
    NgForOf
  ],
  templateUrl: './selector-platform.component.html',
  styleUrl: './selector-platform.component.scss'
})
export class SelectorPlatformComponent implements OnDestroy {
  subscriptions: Subscription[] = []
  platforms!: PlatformsApiInterface[]

  selectedPlatform!: string

  constructor(
    private platformApiService: PalataformsApiService,
    private platformStore: PlatformStoreService
  ) {

    try {
      // this.selectedPlatform = parseJson(this.platformStore.getPlatformFromStorage()!).id
      this.platformStore.platform.subscribe((platform) => {
        if (platform) this.selectedPlatform = platform.id!
      })
      /*this.subscriptions.push(
        this.platformApiService.get().subscribe((platforms: any) => {
          if (platforms) {
            this.platforms = platforms
          }
        })
      )*/
      this.subscriptions.push(
        this.platformStore.availablePlatforms.subscribe(platforms => {
            if (platforms) this.platforms = platforms
          }
        )
      )
    } catch (e) {
      this.subscriptions.push(
        this.platformStore.availablePlatforms.subscribe(platforms => {
            if (platforms) this.platforms = platforms
          }
        )
      )
    }

    /* this.subscriptions.push(
       this.platformApiService.get().subscribe((platforms: any) => {
         if (platforms) {
           this.platforms = platforms
           this.selectedPlatform = platforms[0].id
         }
       })
     )*/
  }

  switchPlatform() {
    this.platformStore.setPlatform(this.platforms.find(platform => platform.id == this.selectedPlatform)!)
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe())
  }


}
