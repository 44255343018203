import { Injectable, signal } from "@angular/core";
import {
	PermissionResponseDTO,
	PermissionsApiService,
} from "../permissions-api/permissions-api.service";
import {firstValueFrom} from "rxjs";
import {Criteria} from "@zertifier/criteria";
import {AuthStoreService} from '../../../../auth/infrastructure/services/auth-store/auth-store.service';


export interface Permissions {
	[role: string]: {
		[resource: string]: {
			[action: string]: boolean;
		};
	};
}

@Injectable({
	providedIn: "root",
})
export class PermissionsStoreService {
	readonly permissions = signal<Permissions>({});

	constructor(private permissionsApi: PermissionsApiService, private authStore: AuthStoreService) {}

	async fetchPermissions() {
		const response = await firstValueFrom(this.permissionsApi.get(Criteria.NONE()));
		const permissions: Permissions = {};
		for (const permission of response) {
			let roleGroup = permissions[permission.role];
			if (!roleGroup) {
				roleGroup = {
					[permission.resource]: {
						[permission.action]: permission.allow,
					},
				};
				permissions[permission.role] = roleGroup;
				continue;
			}

			let resourceGroup = roleGroup[permission.resource];
			if (!resourceGroup) {
				resourceGroup = {
					[permission.action]: permission.allow,
				};
				roleGroup[permission.resource] = resourceGroup;
				continue;
			}

			resourceGroup[permission.action] = permission.allow;
		}

		this.permissions.set(permissions);
	}

	async savePermissions() {
		const permissionsToSave: Array<PermissionResponseDTO> = [];
		const permissions = this.permissions();
		for (const [role, resource] of Object.entries(permissions)) {
			for (const [resourceName, action] of Object.entries(resource)) {
				for (const [actionName, allow] of Object.entries(action)) {
					permissionsToSave.push({
						role,
						resource: resourceName,
						action: actionName,
						allow,
					});
				}
			}
		}

		await firstValueFrom(this.permissionsApi.save(permissionsToSave));
	}

  getCurrentRolePermissions(){
    const user = this.authStore.user()
    const permissions = this.permissions();
    if(user && user.role) return permissions[user!.role] || undefined
    else return
  }
}
